<template>
    <div class="main-wrapper">
        <div class="main-tab">
            <el-breadcrumb separator=">" class="customBreadcrumb">
                <el-breadcrumb-item>考试数据</el-breadcrumb-item>
                <el-breadcrumb-item>查看学生数据</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="right">
<!--                <el-button @click="exportExcel('all')">导出当场考试成绩</el-button>-->
<!--                <el-button @click="exportExcel">导出</el-button>-->
              <el-dropdown split-button>
                导出当场考试成绩
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="exportExcel('all')">导出Excel</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="exportPdf('all')">导出PDF</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown split-button style="margin: 0 10px">
                导出
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="exportExcel">导出Excel</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="exportPdf()">导出PDF</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
                <el-button type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <el-table :data="studentList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%"
            :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6', fontSize: '16px' }"
            :cell-style="{ color: '#333333', height: '70px' }" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="100" align="center"></el-table-column>
            <el-table-column prop="nickname" label="学生名称" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.student_name ? scope.row.student_name : scope.row.nickname }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="id_number" label="身份证" align="center"></el-table-column>
            <el-table-column prop="theory_score" label="理论题得分" align="center">
                <template slot-scope="scope">
                    <span>{{theoryScore(scope.row)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="practical_score" label="实操题得分" align="center">
                <template slot-scope="scope">
                    <span>{{operationScore(scope.row)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="合计得分" align="center">
                <template slot-scope="scope">
                    <span>{{totalScore(scope.row)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false">
                        <div class="a-link" @click="viewDetail(scope.row)">
                            查看详情
                        </div>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0" :current-page="listPages.currentPageNum"
            :page-size="listPages.eachPageNum" layout="prev, pager, next, jumper" :total="listPages.total"
            @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
import { examination_student_score, examExamStuList } from '@/utils/apis'

export default {
    data() {
        return {
            examId: this.$route.query.examId || null,
            type: this.$route.query.type || null,
            studentList: [],
            listPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
            teacherToken: localStorage.getItem('teacherToken') || '',
            scoreData: [],
            role: localStorage.getItem('role'),
            hosturl: localStorage.getItem('hosturl'),
        }
    },
    computed: {

    },
    mounted() {
        this.getList();
    },
    methods: {
        theoryScore(row) {
            if (Number(this.type) !== 0) {
                return row.essay_score
            }
            return row.theory_score
        },
        operationScore(row) {
            return Number(this.type) !== 0 ? row.operation_score : Math.floor(row.practical_score)
        },
        totalScore(row) {
            return Number(this.type) !== 0  ? (row.essay_score + row.operation_score) : (Math.floor(row.theory_score) + Math.floor(row.practical_score))
        },
        handleSelectionChange(val) {
            this.scoreData = val.map((item) => {
                return item.student_id ? item.student_id : item.user_id;
            })
        },
        exportExcel(val='') {
            if (!this.scoreData.length && !val.length) {
                return this.$message.warning('请选择要导出数据的考生')
            }
            let formdata = {
                id: Number(this.$route.query.examId),
                data: this.scoreData
            }
            let url = `examination/exportexcel?exam_id=${formdata.id}` + '&type=' + this.$route.query.type;
            if (val === 'all') {
                url += `&stu=all`
            } else  {
                url += `&stu=` + formdata.data.join()
            }
            window.open(url, "_blank")
        },
        exportPdf(val = '') {
          if (val === '' && this.scoreData.length === 0) {
            return this.$message.warning('请选择要导出数据的考生')
          }
          let exam_id = Number(this.$route.query.examId)
          let stu
          let type = Number(this.$route.query.type)
          if (val === 'all') {
            stu = 'all'
          } else {
            stu = this.scoreData.join()
          }
          window.open(`${this.hosturl}examination/exportpdf?exam_id=${exam_id}&stu=${stu}&type=${type}`, '_blank')
          // window.open(`${this.hosturl}examination/exportpdf?exam_id=${exam_id}&stu=${stu}${type ? `&type=${type}` : ''}`, '_blank')
        },
        // 获取列表
        getList() {
            let params = {}
            if ([1,2,5,6].includes(Number(this.type))) {
                params = {
                    exam_id: this.examId,
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum
                }
                examExamStuList(params).then((res) => {
                    this.studentList = res.data.data;
                    this.listPages.total = res.data.total ? res.data.total : 0;
                }).catch((err) => {
                    console.log('err', err)
                })
            } else {
                params = {
                    examination_id: this.examId,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                examination_student_score(params).then((res) => {
                    this.studentList = res.data.list
                    this.listPages.total = res.data.total ? res.data.total : 0;
                }).catch((err) => {
                    console.log('err', err)
                })
            }
        },
        // 切换页面
        pageCurrentChange(val) {
            this.listPages.currentPageNum = val
            this.getList()
        },
        goBack() {
            if (Number(this.role) === 4) {
                this.$router.push({
                    path: '/judges/pwdata',
                })
            }else if(this.role==6){
                this.$router.push({
                    path: '/examManage/examData',
                })
            }
        },
        viewDetail(row) {
          console.log("type",Number(this.type) )
          console.log('role',this.role);
            if (Number(this.type) === 0) {
                this.$router.push({
                    path: '/examManage/examData/theory',
                    query: {
                        id: row.user_id,
                        examId: row.exam_id,
                        type: 0
                    }
                })
                return;
            }
            if (this.type == 1) {
                if (this.role == 6) {
                    this.$router.push({
                        path: '/examManage/examData/goodsInfo',
                        query: {
                            id: row.student_id,
                            examId: this.examId,
                            isGrade: true,
                            type: this.type,
                            method: 'teaExam'
                        }
                    }
                    )
                } else if (this.role == 4) {
                    this.$router.push({
                        path: '/judgesManage/judgesData/goodsInfo',
                        query: {
                            id: row.student_id,
                            examId: this.examId,
                            isGrade: true,
                            type: this.type,
                            method: 'teaExam'
                        }
                    }
                    )
                }
            }
            if(this.type == 5){
              if (this.role == 6) {
                this.$router.push({
                    //   path: '/examManage/examData/onlineShopVisualDesignDetail',
                    path:'/examManage/examData/ProductSeriveDetail',
                      query: {
                        id: row.student_id,
                        examId: this.examId,
                        isGrade: true,
                        type: this.type,
                        method: 'teaExam'
                      }
                    }
                )
              } else if (this.role == 4) {
                this.$router.push({
                      path: '/judgesManage/examData/ProductSeriveDetail',
                      query: {
                        id: row.student_id,
                        examId: this.examId,
                        isGrade: true,
                        type: this.type,
                        method: 'teaExam'
                      }
                      // query: {
                      //     id: row.user_id,
                      //     examId: row.exam_id,
                      //     type: this.type
                      // }
                    }
                )
              }
            }
            if(this.type ==2){
                if(this.role==6){
                    this.$router.push({
                        // path: '/examManage/examData/onlineShopVisualDesignDetail',
                        path:'/examManage/examData/ProductSeriveDetail',
                        query: {
                            id: row.student_id,
                            examId: this.examId,
                            isGrade: true,
                            type: this.type,
                            method: 'teaExam'
                        }
                    })
                }else if (this.role == 4) {
                    this.$router.push({
                        path: '/judgesManage/examData/ProductSeriveDetail',
                        query: {
                            id: row.student_id,
                            examId: this.examId,
                            isGrade: true,
                            type: this.type,
                            method: 'teaExam'
                        }
                        // query: {
                        //     id: row.user_id,
                        //     examId: row.exam_id,
                        //     type: this.type
                        // }
                    }
                    )
                }
            } 
            if(this.type==6) {
                if (this.role == 6) {
                    this.$router.push({
                        // path: '/examManage/examData/onlineShopVisualDesignDetail',
                        path:'/examManage/examData/goodsInfo',
                        query: {
                            id: row.student_id,
                            examId: this.examId,
                            isGrade: true,
                            type: this.type,
                            method: 'teaExam'
                        }
                    }
                    )
                } else if (this.role == 4) {
                    this.$router.push({
                        path: '/judgesManage/examData/ProductSeriveDetail',
                        query: {
                            id: row.student_id,
                            examId: this.examId,
                            isGrade: true,
                            type: this.type,
                            method: 'teaExam'
                        }
                        // query: {
                        //     id: row.user_id,
                        //     examId: row.exam_id,
                        //     type: this.type
                        // }
                    }
                    )
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.main-tab {
    font-size: 16px;
    line-height: 1;
    border-bottom: 1px solid #F1F5FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 39px;
    position: relative;

    .right {
        position: absolute;
        top: -10px;
        right: 0;
    }
}

.a-link {
    color: inherit;
}
</style>